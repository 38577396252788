@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@500;600;700;800;900;1000&display=swap");

/* * {
  font-family: "Rubik", "Suwannaphum";
  margin: ;
} */
body {
  margin: 0px;
}
.size-border {
  background: white;
  text-align: center;
  border-radius: 12px;
  border: 2px solid gray;
}
.dashboard-products-icon {
  width: 40px;
}
.dashboard-client-icon {
  width: 40px;
}
.widget-card-clients {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 2px solid #249d57; */
  border-radius: 16px;
  word-wrap: break-word;
  background-color: #6ea6d06b;
}
.widget-card-cats {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 2px solid #249d57; */
  border-radius: 16px;
  word-wrap: break-word;
  background-color: #1689fc38;
}
.widget-card-products {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 2px solid #249d57; */
  border-radius: 16px;
  word-wrap: break-word;
  background-color: #ac44f436;
}
.widget-card-employees {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 2px solid #249d57; */
  border-radius: 16px;
  word-wrap: break-word;
  background-color: #2c5ede29;
}
.dashboard-user-icon {
  width: 40px;
}

.navbar-display-flex {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  height: 65px;
}
.avatar {
  position: relative;
  /* right: 20px; */
  height: 38px;
  width: 38px;
  border-radius: 50%;
  cursor: pointer;
}
.order-details-sec h2 {
  font-weight: 800;
}
.flex.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.avatarNavbar {
  border: 3px solid rgb(212, 207, 207);
  position: relative;
  width: 45px;
  height: 45px;
  margin-right: 8px;
  top: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.navbar-hover {
  position: relative;
  padding: 10px 10px;
  border-radius: 5px;
  line-height: 0px;
  transition: 0.3s;
}
.navbar-hover:hover {
  background-color: #f0f2f5;
}
.navbar-hover span {
  position: relative;
  top: -5px;
  margin: 0px !important;
  font-size: 16px;
  color: #12283a;
}
.navbar-hover svg {
  color: #12283a;
}
.popover-text {
  font-size: 20px;
  font-weight: 400;
}

.logout-icon {
  font-size: 20px;
  margin-right: 15px;
}
.accountNavbarhover:hover {
  background-color: rgb(230, 247, 255);
  color: #1890ff !important;
  border-radius: 8px;
  cursor: pointer;
}
.accountNavbarhover {
  transition: 0.3s;
  padding: 4px;
  color: black !important;
}

.sub-topnavbar {
  float: right;
  display: flex;
  border-radius: 0.375rem 28px 28px 0.375rem;
  cursor: "pointer";
  margin-right: 15px;
}
.navbar-avata {
  border: 4px solid #fff;
  margin-top: 4px;
}
.search-navbar {
  width: 500px;
  /* top: 0px; */
  position: relative;
  left: 20px;
  border-radius: 30px;
  background-color: #eff1f2;
  border: 2px solid #eff1f2;
}
.search-navbar input {
  background-color: #eff1f2;
  padding: 0px 15px !important;
}

.menu-categories {
  padding-left: 15px;
}
.ant-menu-inline .ant-menu-item::after {
  display: none;
}
.logos {
  padding: 0px !important;
  height: 80px;
  align-items: center;
  display: flex;
}
.vuthy-logo {
  height: auto;
  width: 67%;
  position: relative;
  top: 0px;
}

.widget-card {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 2px solid #249d57; */
  border-radius: 16px;
  word-wrap: break-word;
  background-color: #fff;
}

.widget-card2 {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 3px solid #5f1717; */
  border-radius: 16px;
  background-color: #fff;
}
.widget-card3 {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 3px solid #5c4813; */
  border-radius: 16px;
  background-color: #fff;
}
.widget-card4 {
  padding: 20px;
  transition: all 0.5s ease-in-out;
  position: relative;
  /* border: 3px solid #12283a; */
  border-radius: 16px;
  background-color: #fff;
}

.background-image-widget,
.background-image-widget2,
.background-image-widget3,
.background-image-widget4 {
  padding: 20px;
  border-radius: 50px;
  font-size: 60px;
}
.background-image-widget {
  background: #4e558f;
  color: rgb(241, 241, 241);
}
.background-image-widget2 {
  background: #f15a5a;
  color: rgb(241, 241, 241);
}
.background-image-widget3 {
  background: #f8d175;
  color: rgb(241, 241, 241);
}
.background-image-widget4 {
  background: #4ea8f1;
  color: rgb(241, 241, 241);
}

.text-details {
  color: #2a3071;
  margin-bottom: 0px !important;
  margin-top: 15px;
  font-size: 17px;
}
.text-details2 {
  color: #5f1717;
}
.text-details3 {
  color: #5c4813;
}
.text-details4 {
  color: #12283a;
}
.counter,
.counter2,
.counter3,
.counter4 {
  font-size: 40px;
}
.counter {
  color: #2a3071;
}
.counter2 {
  color: #c73636;
}
.counter3 {
  color: #bf972b;
}
.counter4 {
  color: #3a79ac;
}
.contentContainer {
  width: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
}
.contentContainer-auto {
  width: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}
.user-profile-analysis,
.user-profile-analysis2,
.user-profile-analysis3 {
  border-radius: 8px;
  padding: 15px;
}
.user-profile-analysis {
  background-color: #eef0fd;
}
.user-profile-analysis2 {
  background-color: rgb(255, 238, 238);
}
.user-profile-analysis3 {
  background-color: rgb(255, 249, 234);
}
.span-rank {
  background-color: #fff;
  padding-top: 5px;
  height: 40px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}
.percentage-rank,
.percentage-rank2,
.percentage-rank3 {
  font-weight: bolder;
  font-size: 40px;
  text-align: center !important;
  margin-top: 15px;
}
.percentage-rank {
  color: #2a3071;
}
.percentage-rank2 {
  color: #c73636;
}
.percentage-rank3 {
  color: #bf972b;
}
.header-content {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
}
.tage-icons {
  font-size: 10px;
  margin-right: 5px;
  /* margin-top: 10px; */
}
.contentContainer-width {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  display: block;
  margin: auto;
  margin-top: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ql-toolbar {
  background: #eff1f2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
}
.ql-container {
  min-height: 200px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #f0f2f5;
  font-size: 16px !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  min-height: 150px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.upload-courses-button {
  background-color: rgb(230, 247, 255);
  width: 70px;
  height: 40px;
  padding: 5px;
  border-radius: 8px;
  right: 20px;
  position: absolute;
}
.add-member-button {
  background-color: rgb(82, 51, 255);
  width: 100px;
  height: 30px;
  padding-top: 8px;
  border-radius: 80px;
  right: 20px;
  font-size: 18px;
  position: absolute;
  color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.container-buttons-modal-add-user {
  margin-top: 30px;
}
.modal-add-user .ant-modal-content {
  border-radius: 8px;
  padding: 15px;
}
.icon-close-gray {
  font-size: 25px;
  background: #eff1f2;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding: 5px;
  position: relative;
  top: 10px;
  right: 10px;
}
.icon-close-notifications {
  font-size: 25px;
  /* background: #eff1f2; */
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  padding: 5px;
  position: relative;
}
.container-edit-points {
  margin-top: 10px;
}
.point-row {
  display: flex;
  flex-direction: row;
}
.tag-button-delele-point {
  padding: 5px 15px;
  color: #fff;
  font-size: 13px;
  height: 25px;
  line-height: 15px;
  position: absolute;
  right: 0;
  float: right !important;
}
.tag-button-edit-point {
  padding: 5px 15px;
  color: #fff;
  font-size: 13px;
  height: 25px;
  line-height: 15px;
  position: absolute;
  float: right;
  right: 85px;
}
.title-point {
  font-size: 18px;
}
.title-header-point {
  font-size: 21px;
}
.notifications {
  display: block;
  float: right;
  position: relative;
  right: 20px;
  top: 10px;
}
.notification-icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  font-size: 50px;
  border-radius: 50px;
  color: #c73636;
  background: rgb(252, 232, 232);
}
.notifications-times {
  color: black;
}
.notifications-hover {
  width: 100%;
  border-radius: 8px;
}
.notifications-hover:hover {
  background: rgb(212, 232, 251);
}
.container-notification-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 7px;
}

.container-notifications {
  margin-top: 30px;
  overflow-y: scroll;
  max-height: 350px;
  overflow-x: hidden;
  padding-left: 12px;
  padding-top: 12px;
}
.notifications-search {
  font-size: 20px;
  float: right;
  position: relative;
  right: 10px;
}
.container-icon-seacrch-notifications {
  margin-bottom: 50px !important;
}

/* ------------login---------------- */
.loginContainer {
  width: 600px;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 16px !important;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 1000;
  padding: 30px 0;
}
/* .loginContainer .ant-legacy-form {
  padding: 40px 40px;
} */

.login-form-button {
  width: 100% !important;
}
.login-form {
  width: 81%;
  margin: auto;
}
.login-label {
  border-radius: 4px;
}
.login-logo {
  width: 200px;
  display: grid;
  place-items: center;
}
.loginBackground {
  /* background-image: linear-gradient(
      90deg,
      rgba(154, 154, 154, 0.333) 0%,
      rgba(154, 154, 154, 0.333) 0%,
      rgba(154, 154, 154, 0.333) 0%
    ),
    url("./assets/images/background-2.jpg"); */
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  position: fixed;
  /* overflow: hidden; */
  /* background-image: url("./assets/images/background.jpg"); */
  background-size: 100%;
}
/*--------------register----------------  */
/* .ant-btn {
  min-width: 140px !important;
} */
.postgressMarginTop {
  position: relative;
  top: -15px;
}
.ce-toolbar {
  padding: 0px 5px;
}
.ce-toolbar__content {
  width: calc(100% - 40px) !important;
  max-width: 850px;

  /* min-height: 100px; */
}
.ce-block__content {
  position: relative;
  max-width: 850px;
  margin: 0 auto;
  font-size: 17px;
}
.cdx-block {
  max-width: 100% !important;
}
.codex-editor__redactor {
  padding-bottom: 10px !important;
}
.background-descrption .ant-form-item-control {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  min-height: 180px;
  margin-bottom: 20px;
}
.site-layout .site-layout-background {
  background: #fff !important;
  margin-bottom: 20px;
  border-radius: 8px;
}
.user-setting-container {
  width: 50%;
  margin: 0px auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
}
.user-setting-title {
  margin-bottom: 30px;
}
.user-setting {
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}
.img-responsive {
  width: 100%;
}
.top-article h2 {
  font-size: 22px;
  margin-bottom: 20px;
}
.top-article img {
  border-radius: 4px;
}
.top-article h3 {
  font-size: 18px;
  margin-bottom: 5px;
}
.codex-editor {
  background: #f5f5f5;
  border-radius: 8px;
  min-height: 150px;
}
.book-responsive {
  width: auto;
  height: 100%;
}

.jabarom-logo {
  color: #5c26f6;
  font-size: 100px;
  /* margin-top: -2px; */
  font-weight: 900;
}
.pie-box {
  background-color: #ffffff;
  padding: 40px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
}

.box-container-img {
  height: 200px;
  display: flex;
  justify-content: center;
}
.box-container-img .ant-image-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.detail .title {
  font-weight: 300 !important;
}
.detail-2 .title {
  font-weight: 300 !important;
}
.detail h5 {
  line-height: 14px;
}
.detail h4 {
  line-height: 22px;
  margin-bottom: 18px;
}
.contain-detail {
  display: flex;
  align-items: flex-end;
}
.header-title {
  margin-bottom: 38px !important;
}
.box {
  border-radius: 16px !important;
}

/* antd-desgin-changed */

/* .ant-layout-sider{
  max-width: 200px !important;
  min-width: 200px !important;
  width: 200px !important;
} */

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.tracking-pdf {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.content-add-products {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 18px;
}
.add-products-container {
  /* height: 70vh !important; */
  /* overflow-y: scroll; */
  padding-bottom: 30px;
}
.label-form {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 12px !important;
}
.label-form-2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.second-label {
  color: #c2c2c2;
  margin-bottom: 12px !important;
  margin: 0;
}
.btn-upload {
  width: 295px !important;
}
.pricing-detail {
  width: 300px !important;
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  line-height: 1.8;
}
.total-price {
  font-size: 21px;
  font-weight: 600;
  color: #1890ff;
}
.product-detail-collapse {
  padding: 0px !important;
}
.product-preview-container {
  display: grid;
  place-items: center;
  margin: auto;
  width: 100%;
}
.product-image-container {
  width: 33rem;
  height: 100%;
  /* height: 33rem; */
  background: #fff;
  object-fit: contain;
}
.product-image {
  width: 100%;
  object-fit: contain;
}
.product-image-small {
  width: 100%;
  cursor: pointer;
}
.allow-scroll {
  height: 33rem;
  overflow-y: scroll;
}
.product-name {
  margin: 0;
}
.product-price {
  font-size: 2rem;
  margin: 0;
  color: #1890ff;
}
.description-container {
  width: 85% !important;
}
.discount-tag {
  position: absolute;
  top: 15px;
  left: 25px;
}
